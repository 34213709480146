






















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getComponent, jsonParse } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    AppLayout: () => getComponent("common/AppLayout"),
    RigSchedule: () => getComponent("schedule/RigSchedule"),
    ComponentDetails: () => getComponent("schedule/ComponentDetails"),
    AddSchedule: () => getComponent("schedule/AddSchedule"),
    Multiselect,
  },
})
export default class ScheduleV2 extends mixins() {
  selectedColumnType = "DETAILS";
  userSelectionVal = "";
  yourValue = "";

  // NEEDED
  refreshResponseDataKey = 0;
  addShiftChecklistPopup = false;
  addShiftIsLoading = false;

  // NEEDED
  addShiftGroupSelected = "";

  // Loading views
  dataLoading = false;
  popupBackground = false;
  selectedDayOfWeek: any[] =[];
  repeatChangeValue = "";
  timeOptions: any[] = [];
  dayOptions: any[] = [];
  endTimeOptions: any[] = ["00:00"];
  recurEndTimeOptions: any[] = ["00:00"];
  userOptions: any[] = [];
  shiftNameErrorMessage = "";
  shiftColorErrorMessage = "";
  startTime='';
  endTime='';



  shiftName = '';
  shiftColor = '';
  swatches = [
    '#689df4', '#f3b344', '#ff7e75', '#5faea8', '#95ae5f', '#c15c50', '#b769ff', '#9a5fae',
    '#ff884f', '#ea5bee', '#f94144', '#f3722c', '#f8961e', '#f9c74f', '#43aa8b', '#c9ada7'
  ]

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  setTimeOptions() {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j+=15) {

		  if (i == 0) {
			  if (j == 0) {
				  this.timeOptions.push(`12:00am`);
			  } else {
				  this.timeOptions.push(`12:${pad(j, 2, '0')}am`);
			  }
			  
		  } else if (i < 12) {
			  if (j == 0) {
				  this.timeOptions.push(`${i}:00am`);
			  } else {
				  this.timeOptions.push(`${i}:${pad(j, 2, '0')}am`);
			  }
		  } else if (i == 12) {
			  if (j == 0) {
				  this.timeOptions.push(`${i}:00pm`);
			  } else {
				  this.timeOptions.push(`${i}:${pad(j, 2, '0')}pm`);
			  }
		  } else {
			  if (j == 0) {
				  this.timeOptions.push(`${i - 12}:00pm`);
			  } else {
				  this.timeOptions.push(`${i - 12}:${pad(j, 2, '0')}pm`);
			  }
		  }
      }
    }
    // this.shiftStartTime='00:00';
    this.setEndTimeOptions(this.startTime);
    // this.shiftEndTime='23:00';
  }

  setDayOptions() {
    for (let i = 1; i <= 30; i++) {
      this.dayOptions.push(i);
    }
  }

  setEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.endTimeOptions = this.timeOptions.slice();
    //   for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
    //     this.endTimeOptions.shift();
    //   }
    //   this.endTime = this.endTimeOptions[0];
    }
  }

  setRecurEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.recurEndTimeOptions = this.timeOptions.slice();
    //   for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
    //     this.recurEndTimeOptions.shift();
    //   }
    }
  }

  clickDayOfWeek(value) {
    
    if (this.selectedDayOfWeek.indexOf(value) === -1) {
      this.selectedDayOfWeek.push(value);
    } else {
      this.selectedDayOfWeek.splice(this.selectedDayOfWeek.indexOf(value),1)
    }
  }

  changeRepeatOption(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.repeatChangeValue = value;
    }
  }

  selectSwatch(swatch) {
    this.shiftColor = swatch
  }

  // NEEDED
  addShiftHideClicked() {
    // this.addNewJobPopupInnerShowing = false
    // this.popupBackground = false
    this.$emit("hide-edit-template-clicked");
	// this.$emit("close-add-shift-popup");
  }





  timeToMilitaryTimeString(time) {

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

	  var hour = time.split(":")[0]
	  var minutes = time.split(":")[1]
	  minutes = minutes.replace("am", "")
	  minutes = minutes.replace("pm", "")
	  var meridian = time.includes("am") ? "AM" : "PM"
	  if (meridian == "AM" && hour == 12) {
		  return pad(0, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else if (meridian == "AM") {
		  return pad(hour, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else if (meridian == "PM" && hour == 12) {
		  return pad(12, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else {
		  return pad(parseInt(hour) + 12, 2, '0') + ":" + pad(minutes, 2, '0')
	  }
  }
  

  async addShiftSave() {
    if (this.shiftName == "" || this.shiftColor == "") {
      if (this.shiftName == "") {
        this.shiftNameErrorMessage = "Please input new template's name";
      } else {
        this.shiftNameErrorMessage = ""
      }

      if (this.shiftColor == "") {
        this.shiftColorErrorMessage = "Please select new shift template's color";
      } else {
        this.shiftColorErrorMessage = ""
      }
      return;
    }
    

    // await scheduleModule.postNewShiftTemplate({
    //   shiftName: this.shiftName,
    //   startTime: this.timeToMilitaryTimeString(this.startTime), 
    //   endTime: this.timeToMilitaryTimeString(this.endTime),
    //   shiftColor: this.shiftColor,
    // });

	this.addShiftIsLoading = true
	await scheduleModule.updateShiftScheduleTemplate({
			shiftName: this.shiftName,
			startTime: this.timeToMilitaryTimeString(this.startTime), 
			endTime: this.timeToMilitaryTimeString(this.endTime),
			shiftColor: this.shiftColor,
			shiftTemplateID: scheduleModule.activeShiftTemplateEdit.ID
		})
	this.addShiftIsLoading = false


    // @ts-ignore
    this.addShiftHideClicked();
  }




  addShiftResponseData(addShiftResponseData: any) {
    throw new Error("Method not implemented.");
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setUserOptions(){
    await accountModule.getReassignmentList({useExisting: true});
    const items = accountModule.allUsersList
    this.userOptions.push(items.map(item=>{
      return item.name
    })) 


	this.userOptions.sort(function(a, b) {
		var textA = a.toUpperCase();
		var textB = b.toUpperCase();
		return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
	});


  }



  getDropdownTimeString(time) {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
	var hour_string = parseInt(time.split(":")[0])
	var minute_string = parseInt(time.split(":")[1])
	  if (hour_string == 0) {
		  return `12:${pad(minute_string, 2, '0')}am`
	  } else if (hour_string < 12) {
		return `${hour_string}:${pad(minute_string, 2, '0')}am`
	  } else if (hour_string == 12) {
		return `12:${pad(minute_string, 2, '0')}pm`
	  } else if (hour_string <= 23) {
		  return `${hour_string - 12}:${pad(minute_string, 2, '0')}pm`
	  }
	  return ''
  }


  created() {
    this.setTimeOptions(), 
    this.setDayOptions();
	this.shiftName = scheduleModule.activeShiftTemplateEdit.TemplateName

	this.startTime = this.getDropdownTimeString(scheduleModule.activeShiftTemplateEdit.StartTime)
	this.endTime = this.getDropdownTimeString(scheduleModule.activeShiftTemplateEdit.EndTime)
	this.selectSwatch(scheduleModule.activeShiftTemplateEdit.Color)
  }
}
